var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Popup",
    [
      _c(
        "template",
        { slot: "title" },
        [
          _vm.isEdit
            ? [_vm._v(_vm._s(_vm.$t("Authorization.popupUserForm_Title_Edit")))]
            : [
                _vm._v(
                  _vm._s(_vm.$t("Authorization.popupUserForm_Title_Create"))
                ),
              ],
        ],
        2
      ),
      _c(
        "template",
        { slot: "content" },
        [
          _vm.isLoadingUser
            ? _c("Loading")
            : _c(
                "form",
                {
                  staticClass: "Form",
                  attrs: { id: "form-user" },
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.onSubmitUserForm.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "Form-item required" }, [
                    _c("label", { staticClass: "Form-item-label" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t("Authorization.popupUserForm_Field_Email")
                        )
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "control" },
                      [
                        _c("customTextInput", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: !_vm.isEdit
                                ? "required|max:128|email|verify_email"
                                : "",
                              expression:
                                "!isEdit ? 'required|max:128|email|verify_email' : ''",
                            },
                          ],
                          staticClass: "txt-right-icon",
                          attrs: {
                            id: "input-settings-user-email",
                            name: "email",
                            "data-vv-as": _vm.$t(
                              "Authorization.popupUserForm_Field_Email"
                            ),
                            error: _vm.veeErrors.has("email"),
                            placeholder: _vm.$t(
                              "Authorization.popupUserForm_Field_Email_Placeholder"
                            ),
                            type: "email",
                            readOnly: _vm.isEdit,
                            disabled: _vm.isAccountOwner || _vm.isEdit,
                            isDelayInput: true,
                          },
                          on: { blur: _vm.checkEmail },
                          model: {
                            value: _vm.item.emailAddress,
                            callback: function ($$v) {
                              _vm.$set(_vm.item, "emailAddress", $$v)
                            },
                            expression: "item.emailAddress",
                          },
                        }),
                        !_vm.isAccountOwner
                          ? _c(
                              "Button",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.isPendingEmail,
                                    expression: "isPendingEmail",
                                  },
                                ],
                                attrs: {
                                  variant: "icon loading",
                                  id: "input-settings-user-email-loading",
                                  type: "button",
                                  tabindex: "-1",
                                },
                              },
                              [_c("Loading", { attrs: { theme: "disable" } })],
                              1
                            )
                          : _vm._e(),
                        !_vm.isAccountOwner
                          ? _c(
                              "Button",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.isValidateEmail,
                                    expression: "isValidateEmail",
                                  },
                                ],
                                attrs: {
                                  id: "input-settings-user-email-check",
                                  type: "button",
                                  variant: "icon check",
                                  tabindex: "-1",
                                },
                              },
                              [
                                _c("svg", { staticClass: "icon icon-check" }, [
                                  _c("use", {
                                    attrs: { "xlink:href": "#icon-check" },
                                  }),
                                ]),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "p",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.veeErrors.has("email"),
                            expression: "veeErrors.has('email')",
                          },
                        ],
                        staticClass: "Form-item-help is-danger",
                      },
                      [_vm._v(_vm._s(_vm.veeErrors.first("email")))]
                    ),
                  ]),
                  _c("div", { staticClass: "Form-item required" }, [
                    _c("label", { staticClass: "Form-item-label" }, [
                      _vm._v(
                        _vm._s(_vm.$t("Authorization.popupUserForm_Field_Name"))
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "control" },
                      [
                        _c("customTextInput", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|max:32",
                              expression: "'required|max:32'",
                            },
                          ],
                          ref: "firstField",
                          attrs: {
                            id: "input-user-popup-field-first-name",
                            name: "firstname",
                            "data-vv-as": _vm.$t(
                              "Authorization.popupUserForm_Field_Name"
                            ),
                            error: _vm.veeErrors.has("firstname"),
                            placeholder: _vm.$t(
                              "Authorization.popupUserForm_Field_First_Name_Placeholder"
                            ),
                            disabled:
                              _vm.isAccountOwner ||
                              _vm.isEdit ||
                              !_vm.enableFields ||
                              _vm.ssoUserInfo?.isSsoUser,
                          },
                          model: {
                            value: _vm.item.firstname,
                            callback: function ($$v) {
                              _vm.$set(_vm.item, "firstname", $$v)
                            },
                            expression: "item.firstname",
                          },
                        }),
                        _c("showValidateError", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.veeErrors.has("firstname"),
                              expression: "veeErrors.has('firstname')",
                            },
                          ],
                          attrs: {
                            errorName: _vm.veeErrors.first("firstname"),
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "Form-item required" }, [
                    _c("label", { staticClass: "Form-item-label" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t("Authorization.popupUserForm_Field_Surname")
                        )
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "control" },
                      [
                        _c("customTextInput", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|max:32",
                              expression: "'required|max:32'",
                            },
                          ],
                          attrs: {
                            id: "input-user-popup-field-first-surname",
                            name: "surname",
                            "data-vv-as": _vm.$t(
                              "Authorization.popupUserForm_Field_Surname"
                            ),
                            error: _vm.veeErrors.has("surname"),
                            disabled:
                              _vm.isAccountOwner ||
                              _vm.isEdit ||
                              !_vm.enableFields ||
                              _vm.ssoUserInfo?.isSsoUser,
                            placeholder: _vm.$t(
                              "Authorization.popupUserForm_Field_Surname_Placeholder"
                            ),
                          },
                          model: {
                            value: _vm.item.surname,
                            callback: function ($$v) {
                              _vm.$set(_vm.item, "surname", $$v)
                            },
                            expression: "item.surname",
                          },
                        }),
                        _c("showValidateError", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.veeErrors.has("surname"),
                              expression: "veeErrors.has('surname')",
                            },
                          ],
                          attrs: { errorName: _vm.veeErrors.first("surname") },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "Form-item" }, [
                    _c(
                      "label",
                      { staticClass: "Form-item-label" },
                      [
                        _vm._v(_vm._s(_vm.$t("Register.phoneNumber"))),
                        _vm.renderComponent
                          ? _c("PhoneNumber", {
                              attrs: {
                                selectedCountry: _vm.country,
                                existingPhoneNumber: _vm.newPhoneNumber,
                                isDisabled: true,
                              },
                              on: { getCountryCode: _vm.getCountryCode },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                  !_vm.isEdit && !_vm.ssoUserInfo?.isSsoUser
                    ? _c("div", { staticClass: "Form-item required" }, [
                        _c("label", { staticClass: "Form-item-label" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "Authorization.popupUserForm_Field_Password"
                              )
                            )
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "control" },
                          [
                            _c("customTextInput", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required|min:6",
                                  expression: "'required|min:6'",
                                },
                              ],
                              staticClass: "txt-right-icon",
                              attrs: {
                                id: "input-user-popup-field-password",
                                autocomplete: "new-password",
                                name: "password",
                                "data-vv-as": _vm.$t(
                                  "Authorization.popupUserForm_Field_Name"
                                ),
                                error: _vm.veeErrors.has("password"),
                                placeholder: _vm.$t(
                                  "Authorization.popupUserForm_Field_Password_Placeholder"
                                ),
                                type: _vm.isPasswordType,
                              },
                              model: {
                                value: _vm.item.password,
                                callback: function ($$v) {
                                  _vm.$set(_vm.item, "password", $$v)
                                },
                                expression: "item.password",
                              },
                            }),
                            _c(
                              "Button",
                              {
                                attrs: {
                                  size: "small",
                                  variant: "icon switch",
                                  id: "input-user-popup-field-switch-password",
                                  type: "button",
                                },
                                on: { click: _vm.showPassword },
                              },
                              [
                                _c(
                                  "svg",
                                  {
                                    staticClass: "icon",
                                    class: _vm.isShowPassword
                                      ? "icon-login-eye"
                                      : "icon-login-eye-closed",
                                  },
                                  [
                                    _c("use", {
                                      attrs: {
                                        "xlink:href": _vm.isShowPassword
                                          ? "#icon-login-eye"
                                          : "#icon-login-eye-closed",
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "Form-item required" }, [
                    _c("label", { staticClass: "Form-item-label" }, [
                      _vm._v(
                        _vm._s(_vm.$t("Authorization.popupForm_Field_Language"))
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "control form-item-select" },
                      [
                        _c("svg", { staticClass: "icon icon-down-arrow" }, [
                          _c("use", {
                            attrs: { "xlink:href": "#icon-down-arrow" },
                          }),
                        ]),
                        _c("customSelectInput", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|is_not:-1",
                              expression: "'required|is_not:-1'",
                            },
                          ],
                          attrs: {
                            name: "language",
                            optionData: _vm.Languages,
                            "data-vv-as": _vm.$t(
                              "Authorization.popupForm_Field_Language"
                            ),
                            error: _vm.veeErrors.has("language"),
                            id: "select-users-language",
                            optionIdName: "option-Authorization-language",
                            defaultText: _vm.$t(
                              "Authorization.popupForm_Field_Language_Unselected"
                            ),
                            disabled: _vm.isAccountOwner,
                          },
                          model: {
                            value: _vm.item.languageId,
                            callback: function ($$v) {
                              _vm.$set(_vm.item, "languageId", $$v)
                            },
                            expression: "item.languageId",
                          },
                        }),
                        _c("showValidateError", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.veeErrors.has("language"),
                              expression: "veeErrors.has('language')",
                            },
                          ],
                          attrs: { errorName: _vm.veeErrors.first("language") },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "Form-item required" }, [
                    _c("label", { staticClass: "Form-item-label" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t("Authorization.popupForm_Field_UserGroups")
                        )
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "control form-item-select" },
                      [
                        _c("svg", { staticClass: "icon icon-down-arrow" }, [
                          _c("use", {
                            attrs: { "xlink:href": "#icon-down-arrow" },
                          }),
                        ]),
                        _c("customSelectInput", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|is_not:-1",
                              expression: "'required|is_not:-1'",
                            },
                          ],
                          attrs: {
                            name: "userGroups",
                            optionData: _vm.userGroupList,
                            "data-vv-as": _vm.$t(
                              "Authorization.popupForm_Field_UserGroups"
                            ),
                            error: _vm.veeErrors.has("userGroups"),
                            id: "select-users-user-group",
                            optionIdName: "option-Authorization-user-groups",
                            defaultText: _vm.$t(
                              "Authorization.popupForm_Field_UserGroups_Unselected"
                            ),
                            disabled: _vm.isAccountOwner,
                          },
                          model: {
                            value: _vm.selectedRole,
                            callback: function ($$v) {
                              _vm.selectedRole = $$v
                            },
                            expression: "selectedRole",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "Form-item" },
                    [
                      _c("label", { staticClass: "Form-item-label" }, [
                        _vm._v(_vm._s(_vm.$t("Global.approvalLevel"))),
                      ]),
                      _c("customSelect", {
                        attrs: {
                          componentName: "user-form-approval-level",
                          optionData: _vm.approvalLevels,
                          isDefaultTextActive: false,
                        },
                        model: {
                          value: _vm.item.approvalLevel,
                          callback: function ($$v) {
                            _vm.$set(_vm.item, "approvalLevel", $$v)
                          },
                          expression: "item.approvalLevel",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "Form-item required" }, [
                    _c("div", { staticClass: "Form-item-header" }, [
                      _c("label", { staticClass: "Form-item-label" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "Authorization.popupForm_Field_StorePermission"
                            )
                          )
                        ),
                      ]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "store-permission" },
                      [
                        _c("CustomCheckbox", {
                          staticClass: "popup-item-check",
                          attrs: {
                            id: "checkbox-settings-user-show-store-all-permission",
                            label: _vm.$t(
                              "Authorization.All_Store_Permission_Label"
                            ),
                            disabled: _vm.isAccountOwner,
                          },
                          on: { change: _vm.setAllStorePermission },
                          model: {
                            value: _vm.item.hasPermissionToAllStores,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.item,
                                "hasPermissionToAllStores",
                                $$v
                              )
                            },
                            expression: "item.hasPermissionToAllStores",
                          },
                        }),
                      ],
                      1
                    ),
                    !_vm.isEmptyDefaultStore
                      ? _c("div", { staticClass: "default-store" }, [
                          _c(
                            "svg",
                            { staticClass: "icon icon-vote-star-filled" },
                            [
                              _c("use", {
                                attrs: {
                                  "xlink:href": "#icon-vote-star-filled",
                                },
                              }),
                            ]
                          ),
                          _c("span", { staticClass: "defaut-store-label" }, [
                            _vm._v(_vm._s(_vm.defaultStore.name)),
                          ]),
                        ])
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "Form-item show-MiniPopup relative" },
                      [
                        _c("div", { staticClass: "dropdown-handle" }, [
                          _c(
                            "div",
                            {
                              staticClass: "select dropdown-handle-button",
                              class: { "is-danger": !_vm.isDefaultStoreValid },
                              attrs: {
                                "aria-expanded": _vm.isDropdownOpen,
                                tabindex: "0",
                              },
                              on: {
                                keydown: [
                                  function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    return _vm.openDropdown.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                  function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "space",
                                        32,
                                        $event.key,
                                        [" ", "Spacebar"]
                                      )
                                    )
                                      return null
                                    $event.preventDefault()
                                    return _vm.openDropdown.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                ],
                                click: _vm.openDropdown,
                              },
                            },
                            [
                              _c(
                                "svg",
                                {
                                  staticClass:
                                    "dropdown-handle-button-icon icon icon-down-arrow",
                                },
                                [
                                  _c("use", {
                                    attrs: { "xlink:href": "#icon-down-arrow" },
                                  }),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "dropdown-handle-button-values",
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "Global.toolbar_Stores_PopupHeader"
                                      )
                                    )
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]),
                        _vm.isDropdownOpen
                          ? _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "click-outside",
                                    rawName: "v-click-outside",
                                    value: _vm.closeDropDowns,
                                    expression: "closeDropDowns",
                                  },
                                ],
                                staticClass: "MiniPopup",
                              },
                              [
                                _c("div", { staticClass: "MiniPopup-header" }, [
                                  _c(
                                    "div",
                                    { staticClass: "item-header m-bottom-10" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "Authorization.popupForm_Header"
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                  _c("div", { staticClass: "Search" }, [
                                    _c(
                                      "svg",
                                      {
                                        staticClass:
                                          "icon icon-search Search-icon",
                                      },
                                      [
                                        _c("use", {
                                          attrs: {
                                            "xlink:href": "#icon-search",
                                          },
                                        }),
                                      ]
                                    ),
                                    _vm.search
                                      ? _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-icon Search-close",
                                            attrs: {
                                              id: "btn-user-store-permission-search-clear",
                                            },
                                            on: { click: _vm.clearSearch },
                                          },
                                          [
                                            _vm.loadingStoreByName
                                              ? _c("Loading")
                                              : _vm._e(),
                                            !_vm.loadingStoreByName
                                              ? _c(
                                                  "svg",
                                                  {
                                                    staticClass:
                                                      "icon icon-popup-close",
                                                  },
                                                  [
                                                    _c("use", {
                                                      attrs: {
                                                        "xlink:href":
                                                          "#icon-popup-close",
                                                      },
                                                    }),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.search,
                                          expression: "search",
                                        },
                                      ],
                                      staticClass: "txt Search-txt",
                                      attrs: {
                                        type: "text",
                                        id: "input-user-store-permission-search",
                                        placeholder: _vm.$t(
                                          "Stocks.search_Placeholder"
                                        ),
                                      },
                                      domProps: { value: _vm.search },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.search = $event.target.value
                                        },
                                      },
                                    }),
                                  ]),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "MiniPopup-content" },
                                  [
                                    _c("custom-list-checkbox", {
                                      staticClass:
                                        "settings-user-store-permission",
                                      attrs: {
                                        items: _vm.item.storePermissionList,
                                        allLabel: _vm.$t(
                                          "Authorization.popupForm_Field_StorePermissionAllLabel"
                                        ),
                                        id: "store-permission-list",
                                        compenentName: "Users",
                                        disabled: _vm.isAccountOwner,
                                      },
                                      on: {
                                        delegateChangeDefaultStore:
                                          _vm.changeDefaultStore,
                                        delegateCheckItem: _vm.updateSelected,
                                        delegateCheckAll: _vm.changeSelectAll,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "MiniPopup-footer" },
                                  [
                                    _c(
                                      "Button",
                                      {
                                        attrs: {
                                          id: "btn-user-popup-store-apply",
                                          type: "button",
                                          primary: "",
                                          size: "medium",
                                          variant: "full",
                                        },
                                        on: { click: _vm.openDropdown },
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(_vm.$t("Global.completed"))
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            )
                          : _vm._e(),
                        _c("showValidateError", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.isDefaultStoreValid,
                              expression: "!isDefaultStoreValid",
                            },
                          ],
                          attrs: {
                            errorName: _vm.$t(
                              "Authorization.defaultStoreValidationError"
                            ),
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "Form-item required" }, [
                    _c("div", { staticClass: "Form-item-header" }, [
                      _c("label", { staticClass: "Form-item-label" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "Authorization.popupForm_Field_TransferStorePermission"
                            )
                          )
                        ),
                      ]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "store-permission" },
                      [
                        _c("CustomCheckbox", {
                          staticClass: "popup-item-check",
                          attrs: {
                            id: "checkbox-settings-user-show-transfer-store-all-permission",
                            label: _vm.$t(
                              "Authorization.All_Transfer_Store_Permission_Label"
                            ),
                            disabled: _vm.isAccountOwner,
                          },
                          on: { change: _vm.setAllTransferStorePermission },
                          model: {
                            value:
                              _vm.item.hasPermissionToAllTransferRequestStores,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.item,
                                "hasPermissionToAllTransferRequestStores",
                                $$v
                              )
                            },
                            expression:
                              "item.hasPermissionToAllTransferRequestStores",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "Form-item show-MiniPopup relative" },
                      [
                        _c("div", { staticClass: "dropdown-handle" }, [
                          _c(
                            "div",
                            {
                              staticClass: "select dropdown-handle-button",
                              attrs: {
                                "aria-expanded": _vm.isTransferDropdownOpen,
                                tabindex: "0",
                              },
                              on: {
                                keydown: [
                                  function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    return _vm.openTransferStoreDropdown.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                  function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "space",
                                        32,
                                        $event.key,
                                        [" ", "Spacebar"]
                                      )
                                    )
                                      return null
                                    $event.preventDefault()
                                    return _vm.openTransferStoreDropdown.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                ],
                                click: _vm.openTransferStoreDropdown,
                              },
                            },
                            [
                              _c(
                                "svg",
                                {
                                  staticClass:
                                    "dropdown-handle-button-icon icon icon-down-arrow",
                                },
                                [
                                  _c("use", {
                                    attrs: { "xlink:href": "#icon-down-arrow" },
                                  }),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "dropdown-handle-button-values",
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "Global.toolbar_Transfer_Stores_PopupHeader"
                                      )
                                    )
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]),
                        _vm.isTransferDropdownOpen
                          ? _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "click-outside",
                                    rawName: "v-click-outside",
                                    value: _vm.closeDropDowns,
                                    expression: "closeDropDowns",
                                  },
                                ],
                                staticClass: "MiniPopup",
                              },
                              [
                                _c("div", { staticClass: "MiniPopup-header" }, [
                                  _c(
                                    "div",
                                    { staticClass: "item-header m-bottom-10" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "Authorization.popupForm_Header"
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                  _c("div", { staticClass: "Search" }, [
                                    _c(
                                      "svg",
                                      {
                                        staticClass:
                                          "icon icon-search Search-icon",
                                      },
                                      [
                                        _c("use", {
                                          attrs: {
                                            "xlink:href": "#icon-search",
                                          },
                                        }),
                                      ]
                                    ),
                                    _vm.transferStoreSearch
                                      ? _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-icon Search-close",
                                            attrs: {
                                              id: "btn-user-store-permission-search-clear",
                                            },
                                            on: { click: _vm.clearSearch },
                                          },
                                          [
                                            _vm.loadingStoreByName
                                              ? _c("Loading")
                                              : _vm._e(),
                                            !_vm.loadingStoreByName
                                              ? _c(
                                                  "svg",
                                                  {
                                                    staticClass:
                                                      "icon icon-popup-close",
                                                  },
                                                  [
                                                    _c("use", {
                                                      attrs: {
                                                        "xlink:href":
                                                          "#icon-popup-close",
                                                      },
                                                    }),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.transferStoreSearch,
                                          expression: "transferStoreSearch",
                                        },
                                      ],
                                      staticClass: "txt Search-txt",
                                      attrs: {
                                        type: "text",
                                        id: "input-user-store-permission-search",
                                        placeholder: _vm.$t(
                                          "Stocks.search_Placeholder"
                                        ),
                                      },
                                      domProps: {
                                        value: _vm.transferStoreSearch,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.transferStoreSearch =
                                            $event.target.value
                                        },
                                      },
                                    }),
                                  ]),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "MiniPopup-content" },
                                  [
                                    _c("custom-list-checkbox", {
                                      staticClass:
                                        "settings-user-store-permission",
                                      attrs: {
                                        items:
                                          _vm.item.transferStorePermissionList,
                                        allLabel: _vm.$t(
                                          "Authorization.popupForm_Field_StorePermissionAllLabel"
                                        ),
                                        id: "store-permission-list",
                                        compenentName: "Users",
                                        withStar: false,
                                        disabled: _vm.isAccountOwner,
                                      },
                                      on: {
                                        delegateCheckItem: _vm.updateSelected,
                                        delegateCheckAll:
                                          _vm.changeSelectAllTransfer,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "MiniPopup-footer" },
                                  [
                                    _c(
                                      "Button",
                                      {
                                        attrs: {
                                          id: "btn-user-popup-store-apply",
                                          type: "button",
                                          primary: "",
                                          size: "medium",
                                          variant: "full",
                                        },
                                        on: {
                                          click: _vm.openTransferStoreDropdown,
                                        },
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(_vm.$t("Global.completed"))
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    ),
                  ]),
                ]
              ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "Button",
            {
              attrs: {
                primary: "",
                variant: "full",
                id: _vm.isEdit
                  ? "btn-user-popup-submit-edit"
                  : "btn-user-popup-submit-new",
                type: "submit",
                form: "form-user",
                disabled: _vm.isLoading,
              },
            },
            [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isLoading,
                      expression: "!isLoading",
                    },
                  ],
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.isEdit
                        ? _vm.$t("Authorization.popupUserForm_Button_Edit")
                        : _vm.$t("Authorization.popupUserForm_Button_Create")
                    )
                  ),
                ]
              ),
              _c("Loading", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isLoading,
                    expression: "isLoading",
                  },
                ],
                attrs: { theme: "disable" },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }