<template lang="pug">
Popup

  template(slot="title")
    template(v-if="isEdit") {{ $t('Authorization.popupUserForm_Title_Edit') }}
    template(v-else) {{ $t('Authorization.popupUserForm_Title_Create') }}

  template(slot="content")
    Loading(v-if="isLoadingUser")
    form.Form(
    v-else
    id="form-user"
    @submit.prevent="onSubmitUserForm")

      .Form-item.required
        label.Form-item-label {{ $t('Authorization.popupUserForm_Field_Email') }}
        .control
          customTextInput.txt-right-icon(
            id="input-settings-user-email"
            v-model="item.emailAddress"
            v-validate="!isEdit ? 'required|max:128|email|verify_email' : ''"
            name="email"
            :data-vv-as="$t('Authorization.popupUserForm_Field_Email')"
            :error="veeErrors.has('email')"
            :placeholder="$t('Authorization.popupUserForm_Field_Email_Placeholder')"
            type="email"
            :readOnly="isEdit"
            :disabled="isAccountOwner || isEdit"
            :isDelayInput="true"
            @blur="checkEmail"
          )
          //
          Button(
          v-if="!isAccountOwner"
          variant="icon loading"
          id="input-settings-user-email-loading"
          type="button",
          tabindex="-1",
          v-show="isPendingEmail")
            Loading(theme="disable")
          Button(
          v-if="!isAccountOwner"
          id="input-settings-user-email-check"
          type="button",
          variant="icon check"
          tabindex="-1",
          v-show="isValidateEmail")
            svg.icon.icon-check
              use(xlink:href="#icon-check")
          //
        p.Form-item-help.is-danger(
        v-show="veeErrors.has('email')")
          | {{ veeErrors.first('email') }}
      
      .Form-item.required
        label.Form-item-label {{ $t('Authorization.popupUserForm_Field_Name') }}
        .control
          customTextInput(
            id="input-user-popup-field-first-name"
            ref="firstField"
            v-model="item.firstname"
            v-validate="'required|max:32'"
            name="firstname"
            :data-vv-as="$t('Authorization.popupUserForm_Field_Name')"
            :error="veeErrors.has('firstname')"
            :placeholder="$t('Authorization.popupUserForm_Field_First_Name_Placeholder')"
            :disabled="isAccountOwner || isEdit || !enableFields || ssoUserInfo?.isSsoUser"
          )

          showValidateError(
            v-show="veeErrors.has('firstname')"
            :errorName="veeErrors.first('firstname')"
          )

      .Form-item.required
        label.Form-item-label {{ $t('Authorization.popupUserForm_Field_Surname') }}
        .control
          customTextInput(
            id="input-user-popup-field-first-surname"
            v-model="item.surname"
            v-validate="'required|max:32'"
            name="surname"
            :data-vv-as="$t('Authorization.popupUserForm_Field_Surname')"
            :error="veeErrors.has('surname')"
            :disabled="isAccountOwner || isEdit || !enableFields || ssoUserInfo?.isSsoUser"
            :placeholder="$t('Authorization.popupUserForm_Field_Surname_Placeholder')"
          )

          showValidateError(
            v-show="veeErrors.has('surname')"
            :errorName="veeErrors.first('surname')"
          )

      

      .Form-item
        label.Form-item-label {{ $t('Register.phoneNumber') }}
          PhoneNumber(
            v-if="renderComponent"
            :selectedCountry="country"
            :existingPhoneNumber="newPhoneNumber"
            :isDisabled="true"
            @getCountryCode="getCountryCode"
          )
      .Form-item.required(v-if="!isEdit && !ssoUserInfo?.isSsoUser")
        label.Form-item-label {{ $t('Authorization.popupUserForm_Field_Password') }}
        .control
          customTextInput.txt-right-icon(
            id="input-user-popup-field-password"
            v-model="item.password"
            autocomplete="new-password",
            v-validate="'required|min:6'"
            name="password"
            :data-vv-as="$t('Authorization.popupUserForm_Field_Name')"
            :error="veeErrors.has('password')"
            :placeholder="$t('Authorization.popupUserForm_Field_Password_Placeholder')"
            :type="isPasswordType"
          )

          Button(
          size="small"
          variant="icon switch"
          id="input-user-popup-field-switch-password"
          type="button",
          @click="showPassword")
            svg.icon(:class="isShowPassword ? 'icon-login-eye' : 'icon-login-eye-closed'")
              use(:xlink:href="isShowPassword ? '#icon-login-eye' : '#icon-login-eye-closed'")
      .Form-item.required
        label.Form-item-label {{ $t('Authorization.popupForm_Field_Language') }}
        .control.form-item-select
          svg.icon.icon-down-arrow
            use(xlink:href="#icon-down-arrow")
          customSelectInput(
            name="language"
            :optionData="Languages"
            v-model="item.languageId"
            v-validate="'required|is_not:-1'"
            :data-vv-as="$t('Authorization.popupForm_Field_Language')"
            :error="veeErrors.has('language')"
            id="select-users-language"
            optionIdName="option-Authorization-language"
            :defaultText="$t('Authorization.popupForm_Field_Language_Unselected')"
            :disabled="isAccountOwner"
          )
          showValidateError(
            v-show="veeErrors.has('language')"
            :errorName="veeErrors.first('language')"
          )

      .Form-item.required
        label.Form-item-label {{ $t('Authorization.popupForm_Field_UserGroups') }}
        .control.form-item-select
          svg.icon.icon-down-arrow
            use(xlink:href="#icon-down-arrow")
          customSelectInput(
            name="userGroups"
            :optionData="userGroupList"
            v-model="selectedRole"
            v-validate="'required|is_not:-1'"
            :data-vv-as="$t('Authorization.popupForm_Field_UserGroups')"
            :error="veeErrors.has('userGroups')"
            id="select-users-user-group"
            optionIdName="option-Authorization-user-groups"
            :defaultText="$t('Authorization.popupForm_Field_UserGroups_Unselected')"
            :disabled="isAccountOwner"
          )
      .Form-item
        label.Form-item-label {{ $t('Global.approvalLevel') }}
        customSelect(
          componentName="user-form-approval-level"
          v-model="item.approvalLevel"
          :optionData="approvalLevels"
          :isDefaultTextActive="false"
        )

      .Form-item.required
        .Form-item-header
          label.Form-item-label {{ $t('Authorization.popupForm_Field_StorePermission') }}

        .store-permission
          CustomCheckbox.popup-item-check(
          id="checkbox-settings-user-show-store-all-permission"
          :label="$t('Authorization.All_Store_Permission_Label')"
          @change="setAllStorePermission"
          v-model="item.hasPermissionToAllStores"
          :disabled="isAccountOwner")

        .default-store(v-if="!isEmptyDefaultStore")
          svg.icon.icon-vote-star-filled
            use(xlink:href="#icon-vote-star-filled")
          span.defaut-store-label {{ defaultStore.name }}
        .Form-item.show-MiniPopup.relative
          .dropdown-handle
            .select.dropdown-handle-button(
              @keydown.enter="openDropdown"
              @keydown.prevent.space="openDropdown"
              @click="openDropdown"
              :aria-expanded="isDropdownOpen"
              :class="{'is-danger': !isDefaultStoreValid}"
              tabindex="0"
            )
              svg.dropdown-handle-button-icon.icon.icon-down-arrow
                use(xlink:href="#icon-down-arrow")
              
              .dropdown-handle-button-values {{ $t('Global.toolbar_Stores_PopupHeader')}}

          .MiniPopup(v-if="isDropdownOpen" v-click-outside="closeDropDowns")
            .MiniPopup-header

              .item-header.m-bottom-10 {{ $t('Authorization.popupForm_Header') }}

              .Search
                svg.icon.icon-search.Search-icon
                  use(xlink:href="#icon-search")
                button.btn.btn-icon.Search-close(
                id="btn-user-store-permission-search-clear"
                v-if="search",
                @click="clearSearch")
                  Loading(v-if="loadingStoreByName")
                  svg.icon.icon-popup-close(v-if="!loadingStoreByName")
                    use(xlink:href="#icon-popup-close")
                input.txt.Search-txt(
                type="text"
                id="input-user-store-permission-search"
                v-model="search"
                :placeholder="$t('Stocks.search_Placeholder')")
            .MiniPopup-content
              custom-list-checkbox(
                :items="item.storePermissionList"
                :allLabel="$t('Authorization.popupForm_Field_StorePermissionAllLabel')"
                id="store-permission-list"
                compenentName="Users"
                class="settings-user-store-permission"
                :disabled="isAccountOwner"
                @delegateChangeDefaultStore="changeDefaultStore"
                @delegateCheckItem="updateSelected"
                @delegateCheckAll="changeSelectAll"
              )

            .MiniPopup-footer
              Button( 
                id="btn-user-popup-store-apply"
                type="button"
                primary
                size="medium"
                variant="full"
                @click="openDropdown"
              )
                span {{$t('Global.completed')}}

          showValidateError(
            v-show="!isDefaultStoreValid"
            :errorName="$t('Authorization.defaultStoreValidationError')")

      .Form-item.required
        .Form-item-header
          label.Form-item-label {{ $t('Authorization.popupForm_Field_TransferStorePermission') }}

        .store-permission
          CustomCheckbox.popup-item-check(
          id="checkbox-settings-user-show-transfer-store-all-permission"
          :label="$t('Authorization.All_Transfer_Store_Permission_Label')"
          @change="setAllTransferStorePermission"
          v-model="item.hasPermissionToAllTransferRequestStores"
          :disabled="isAccountOwner")

        .Form-item.show-MiniPopup.relative
          .dropdown-handle
            .select.dropdown-handle-button(
              @keydown.enter="openTransferStoreDropdown"
              @keydown.prevent.space="openTransferStoreDropdown"
              @click="openTransferStoreDropdown"
              :aria-expanded="isTransferDropdownOpen"
              tabindex="0"
            )
              svg.dropdown-handle-button-icon.icon.icon-down-arrow
                use(xlink:href="#icon-down-arrow")
              
              .dropdown-handle-button-values {{ $t('Global.toolbar_Transfer_Stores_PopupHeader')}}
          .MiniPopup(v-if="isTransferDropdownOpen" v-click-outside="closeDropDowns")
            .MiniPopup-header

              .item-header.m-bottom-10 {{ $t('Authorization.popupForm_Header') }}

              .Search
                svg.icon.icon-search.Search-icon
                  use(xlink:href="#icon-search")
                button.btn.btn-icon.Search-close(
                id="btn-user-store-permission-search-clear"
                v-if="transferStoreSearch",
                @click="clearSearch")
                  Loading(v-if="loadingStoreByName")
                  svg.icon.icon-popup-close(v-if="!loadingStoreByName")
                    use(xlink:href="#icon-popup-close")
                input.txt.Search-txt(
                type="text"
                id="input-user-store-permission-search"
                v-model="transferStoreSearch"
                :placeholder="$t('Stocks.search_Placeholder')")
            .MiniPopup-content
              custom-list-checkbox(
                :items="item.transferStorePermissionList"
                :allLabel="$t('Authorization.popupForm_Field_StorePermissionAllLabel')"
                id="store-permission-list"
                compenentName="Users"
                :withStar="false"
                class="settings-user-store-permission"
                :disabled="isAccountOwner"
                @delegateCheckItem="updateSelected"
                @delegateCheckAll="changeSelectAllTransfer"
              )

            .MiniPopup-footer
              Button( 
                id="btn-user-popup-store-apply"
                type="button"
                primary
                size="medium"
                variant="full"
                @click="openTransferStoreDropdown"
              )
                span {{$t('Global.completed')}}
    

  template(slot="footer")
    Button(
    primary
    variant="full"
    :id="isEdit ? 'btn-user-popup-submit-edit' : 'btn-user-popup-submit-new'"
    type="submit"
    form="form-user"
    :disabled="isLoading")
      span(v-show="!isLoading") {{ isEdit ? $t('Authorization.popupUserForm_Button_Edit') : $t('Authorization.popupUserForm_Button_Create') }}
      Loading(theme="disable", v-show="isLoading")

    

</template>

<script>
import { debouncer, vueWaitLoader } from '@/utils/baseOperations'
import { mapFields, Validator } from 'vee-validate'
import { mapActions, mapGetters } from 'vuex'
import CustomListCheckbox from './custom-list-checkbox.vue'
import PhoneNumber from '@/view/global/phone-number'
import customSelect from '@/view/global/custom-select'

export default {
  name: 'UserForm',

  props: {
    status: {
      type: String,
      default: 'new'
    }
  },

  components: {
    CustomListCheckbox,
    customSelect,
    PhoneNumber
  },

  data () {
    return {
      search: '',
      transferStoreSearch: '',
      renderComponent: true,
      enableFields: false,
      item: {
        isActive: true,
        emailAddress: '',
        firstname: '',
        surname: '',
        password: '',
        languageId: '-1',
        roleList: [],
        hasPermissionToAllStores: true,
        hasPermissionToAllTransferRequestStores: true,
        storePermissionList: [],
        transferStorePermissionList: [],
        defaultStoreId: '',
        approvalLevel: 0,
        phoneNumber: null
      },
      defaultStore: {},
      isDropdownOpen: false,
      selectedRole: '-1',
      typePassword: false,
      radioStatus: [
        {
          name: 'active',
          label: this.$t('Authorization.User_isActive_Text'),
          isChecked: true
        },
        {
          name: 'passive',
          label: this.$t('Authorization.User_isPassive_Text'),
          isChecked: false
        }
      ],
      inValidForDefaultStore: false,
      isTransferDropdownOpen: false,
      isDefaultStoreValid: true,
      approvalLevels: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
      country: !this.isEdit ? {long: 'Turkey', code: '+90', short: 'TR'} : {},
      phone: null,
      newPhoneNumber: null
    }
  },

  async beforeMount () {
    if (!this.isEdit) {
      await this.fetchStores()
      this.item.storePermissionList = JSON.parse(JSON.stringify(this.StoreList.list))
      this.item.transferStorePermissionList = JSON.parse(JSON.stringify(this.StoreList.list))
    }
    if (this.isEdit) {
      await this.init()
      // await this.formFocus()
    }
    this.checkEmail()
  },

  watch: {
    search: debouncer(async function (val) {
      if (val.length >= 2) {
        await this.fetchStoreByName(val)

        this.item.storePermissionList.map(store => {
          if (this.SearchStoreList.length === 0) {
            this.item.storePermissionList.map(store => {
              store.isSearch = false
            })
          } else {
            for (let i in this.SearchStoreList) {
              if (store.id === this.SearchStoreList[i].id) {
                store.isSearch = true
                return
              } else {
                store.isSearch = false
              }
            }
          }
        })
      } else {
        this.item.storePermissionList.map(store => {
          store.isSearch = true
        })
      }
    }, 500),
    transferStoreSearch: debouncer(async function (val) {
      if (val.length >= 2) {
        await this.fetchStoreByName(val)
        this.item.transferStorePermissionList.map(store => {
          if (this.SearchStoreList.length === 0) {
            this.item.transferStorePermissionList.map(store => {
              store.isSearch = false
            })
          } else {
            for (let i in this.SearchStoreList) {
              if (store.id === this.SearchStoreList[i].id) {
                store.isSearch = true
                return
              } else {
                store.isSearch = false
              }
            }
          }
        })
      } else {
        this.item.transferStorePermissionList.map(store => {
          store.isSearch = true
        })
      }
    }, 500),
  },


  computed: {

    ...mapGetters('Settings', [
      'userGroupList',
      'StoreList',
      'SearchStoreList',
      'TransferRequestStorePermissionList',
      'ssoUserInfo'
    ]),

    ...mapGetters('Auth', [
      'User'
    ]),

    ...mapGetters('Global', [
      'Languages'
    ]),

    ...mapFields({
      flagFirstname: 'firstname',
      flagSurname: 'surname',
      flagEmail: 'email',
      flagPassword: 'password'
    }),

    isAccountOwner () {
      return this.item.type === 3
    },

    isPendingEmail () {
      return this.flagEmail.pending
    },

    isValidateEmail () {
      return this.flagEmail.valid && !this.isPendingEmail
    },

    isPasswordType () {
      return this.typePassword ? 'text' : 'password'
    },

    isShowPassword () {
      return this.typePassword
    },

    isLoadingUser () {
      return this.$wait.is(['fetchUser'])
    },

    isLoading () {
      return this.$wait.is(['updateUser', 'createUser', 'onSubmitUser'])
    },

    loadingStoreByName () {
      return this.$wait.is(['Settings/fetchStores'])
    },

    isEmptyPermissionList () {
      let count = 0
      this.item.storePermissionList.map(store =>
        store.isChecked ? count++ : false
      )
      return count === 0
    },

    isEmptyDefaultStore () {
      return !this.defaultStore.defaultStore
    },

    isEdit () {
      return this.status === 'edit'
    },

    isPendingName () {
      return this.flagsName.pending
    },

    isValidateName () {
      if (this.flagsName.required) {
        return this.flagsName.valid &&
        !this.isPendingName
      } else {
        return this.flagsName.valid &&
        this.item.name !== '' &&
        this.item.name !== null &&
        !this.isPendingName
      }
    }
  },

  methods: {
    ...mapActions('Notify', [
      'notifyShow'
    ]),

    ...mapActions('Settings', [
      'fetchUserList',
      'fetchUser',
      'createUser',
      'updateUser',
      'deleteUser',
      'fetchPermissionRoleList',
      'fetchStores',
      'fetchStoreByName',
      'checkUserEmail'
    ]),

    forceRerender () {
      this.renderComponent = false
      this.$nextTick(() => {
        this.renderComponent = true
      })
    },

    checkEmail(value) {
      if (!this.isEdit) {
        const verifyEmail = {
        getMessage(field, params, data) {
          return (data && data.message) || 'Something wrong';
        },
        validate: value => {
          return new Promise(resolve => {
            let params = {
              EmailAddress: value
            };
            this.checkUserEmail(params)
              .then(res => {
                if (res) {
                  resolve({
                    valid: res.data.isAvailable
                  });
                  if (this.ssoUserInfo && this.ssoUserInfo.isAvailable && this.ssoUserInfo.isSsoUser) {
                    this.item.firstname = this.ssoUserInfo.userSsoModel.firstName
                    this.item.surname = this.ssoUserInfo.userSsoModel.lastName
                    this.country.code = this.ssoUserInfo.userSsoModel.phoneCode
                    this.newPhoneNumber = this.ssoUserInfo.userSsoModel.phoneNumber
                    this.forceRerender()
                  }
                  else {
                    this.item.firstname = ''
                    this.item.surname = ''
                    this.country.code = ''
                    this.newPhoneNumber = null
                    this.enableFields = true
                    this.forceRerender()
                  }
                  this.$nextTick(() => {});
                } else {
                  resolve({
                    valid: false
                  });
                }
              });
          });
        }
      };

      const verifyEmailMessages = {
        tr: {
          messages: {
            verify_email: field => this.$t('Authorization.ValidErrorForUserEmail')
          }
        }
      };

      Validator.extend('verify_email', verifyEmail);
      Validator.localize(verifyEmailMessages);
      }
      
   },

    validateDefaultStore () {
      if (this.isEmptyDefaultStore) this.isDefaultStoreValid = false
    },

    openDropdown () {
      this.isDropdownOpen = !this.isDropdownOpen
    },

    openTransferStoreDropdown () {
      this.isTransferDropdownOpen = !this.isTransferDropdownOpen
    },

    closeDropDowns () {
      this.isDropdownOpen = false
      this.isTransferDropdownOpen = false
    },

    clearSearch () {
      this.search = ''
      this.transferStoreSearch = ''
    },

    changeDefaultStore (item) {
      if (item.isChecked) {
        this.isDefaultStoreValid = true
        this.item.storePermissionList.map(store => {
          if (item.id === store.id) {
            store.defaultStore = !store.defaultStore
          } else {
            store.defaultStore = false
          }
        })
      }
      if (item.defaultStore) {
        this.defaultStore = item
        this.item.defaultStoreId = item.id
      }
    },

    showPassword () {
      this.typePassword = !this.typePassword
    },

    getCountryCode (item) {
      if (item) this.country = item
      else {
        this.country = {
          short: 'TR',
          long: 'Turkey',
          code: '+90'
        }
      }
    },

    getPhoneNumber(number) {
      this.newPhoneNumber = number
    },

    async init () {
      let userId = this.$route.params.id

      await this.fetchStores()
      this.item.storePermissionList = JSON.parse(JSON.stringify(this.StoreList.list))
      this.item.transferStorePermissionList = JSON.parse(JSON.stringify(this.StoreList.list))

      await this.fetchUser(userId).then(res => {
        if (res.status === 200) {
          if (res.data.user.phoneNumber) {
            this.phone = res.data.user.phoneNumber ? res.data.user.phoneNumber.split('-') : null
            this.country.code = this.phone[0]
            this.newPhoneNumber = this.phone[1]
          }
          this.item = {
            isActive: res.data.user.isActive,
            emailAddress: res.data.user.emailAddress,
            firstname: res.data.user.firstname,
            surname: res.data.user.surname,
            languageId: res.data.user.languageId,
            roleList: res.data.roleList || -1,
            hasPermissionToAllStores: res.data.user.hasPermissionToAllStores,
            hasPermissionToAllTransferRequestStores: res.data.user.hasPermissionToAllTransferRequestStores,
            storePermissionList: this.item.storePermissionList,
            transferStorePermissionList: this.item.transferStorePermissionList,
            defaultStoreId: res.data.user.defaultStoreId,
            type: res.data.user.type,
            approvalLevel: res.data.approvalLevel,
            phoneNumber: this.phone ? this.phone[1] : ''
          }
          this.selectedRole = this.item.roleList[0].roleId
          this.item.storePermissionList.map(store => {
            if (store.id === res.data.user.defaultStoreId) {
              store.defaultStore = true
              this.defaultStore = store
            } else {
              store.defaultStore = false
            }
            for (let i in res.data.storePermissionList) {
              if (store.name === res.data.storePermissionList[i].name) {
                store.isChecked = true
                return
              } else {
                store.isChecked = false
              }
            }
          })
          this.item.transferStorePermissionList.map(transferStore => {
            for (let i in res.data.transferRequestStorePermissionList) {
              if (transferStore.name === res.data.transferRequestStorePermissionList[i].name) {
                transferStore.isChecked = true
                return
              } else {
                transferStore.isChecked = false
              }
            }
          })
        }
      })
    },

    close () {
      this.$router.go(-1)
    },

    setAllStorePermission () {
      if (this.item.hasPermissionToAllStores) {
        this.item.storePermissionList.map(store => {
          store.isChecked = true
        })
      }
    },
    setAllTransferStorePermission () {
      if (this.item.hasPermissionToAllTransferRequestStores) {
        this.item.transferStorePermissionList.map(store => {
          store.isChecked = true
        })
      }
    },
    changeSelectAll (isChecked) {
      this.item.storePermissionList.forEach(store => {
        if (store.isSearch) {
          store.isChecked = isChecked
          if (isChecked === false) {
            store.defaultStore = false
          }
        }
      })
    },

    changeSelectAllTransfer (isChecked) {
      this.item.transferStorePermissionList.forEach(store => {
        if (store.isSearch) {
          store.isChecked = isChecked
        }
      })
    },

    updateSelected (event) {
      event.item.isChecked = !event.item.isChecked
      if (event.item.isChecked === false) {
        event.item.defaultStore = false
      }
    },

    formFocus () {
      this.$refs.firstField.focusCustomInput()
    },

    onSubmitUserForm: vueWaitLoader(async function () {
      if (this.newPhoneNumber && this.newPhoneNumber.length > 15) return
      await this.$validator.validateAll().then(result => {
        // form error
        this.validateDefaultStore()
        if (!this.isDefaultStoreValid) return

        if (!result) return

        if (this.isEdit) {
          return this.submitEdit()
        }
        return this.submitNew()
      })
    }, 'onSubmitUser'),

    async submitNew () {
      this.item.roleList.push(this.selectedRole)
      let storePermissionList = []
      let transferStorePermissionList = []
      this.item.storePermissionList.map(store => {
        if (store.isChecked) {
          storePermissionList.push(store.id)
        }
      })
      this.item.transferStorePermissionList.map(store => {
        if (store.isChecked) {
          transferStorePermissionList.push(store.id)
        }
      })
      await this.createUser({
        emailAddress: this.item.emailAddress,
        firstname: this.item.firstname,
        surname: this.item.surname,
        password: this.item.password,
        languageId: this.item.languageId,
        roleList: this.item.roleList,
        hasPermissionToAllStores: this.item.hasPermissionToAllStores,
        hasPermissionToAllTransferRequestStores: this.item.hasPermissionToAllTransferRequestStores,
        storePermissionList: storePermissionList,
        transferRequestStorePermissionList: transferStorePermissionList,
        defaultStoreId: this.item.defaultStoreId,
        approvalLevel: this.item.approvalLevel
      })
        .then(res => {
          if (res) {
            const message = this.$t('Global.notification_Created', {
              name: this.item.firstname + ' ' + this.item.surname
            })
            this.notifyShow({ message })
            this.$emit('getUserList')
            this.close()
          }
        })
    },

    async submitEdit () {
      let userId = this.$route.params.id

      this.item.roleList[0] = this.selectedRole

      let storePermissionList = []
      let transferStorePermissionList = []
      this.item.storePermissionList.map(store => {
        if (store.isChecked) {
          storePermissionList.push(store.id)
        }
      })
      this.item.transferStorePermissionList.map(store => {
        if (store.isChecked) {
          transferStorePermissionList.push(store.id)
        }
      })

      await this.updateUser({
        id: userId,
        isActive: this.item.isActive,
        firstname: this.item.firstname,
        surname: this.item.surname,
        languageId: this.item.languageId,
        roleList: this.item.roleList,
        hasPermissionToAllStores: this.item.hasPermissionToAllStores,
        hasPermissionToAllTransferRequestStores: this.item.hasPermissionToAllTransferRequestStores,
        storePermissionList: storePermissionList,
        transferRequestStorePermissionList: transferStorePermissionList,
        defaultStoreId: this.item.defaultStoreId,
        approvalLevel: this.item.approvalLevel,
        phoneNumber: this.newPhoneNumber ? this.country.code + '-' + this.newPhoneNumber : null
      }).then(res => {
        if (res) {
          const message = this.$t('Global.notification_Updated', {
            name: this.item.firstname + ' ' + this.item.surname
          })
          this.notifyShow({ message })
          this.$emit('getUserList')
          this.close()
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>

  @import "~@/stylesheet/config/mixin";

 .dropdown-handle {
    display: flex;
    align-items: center;
    font-size: $font-size-micro;
    user-select: none;
    border: 1px solid $color-gray;
    border-radius: $border-high-radius;

    .disabled-button {
      pointer-events: none;
      opacity: 0.6;
    }

    &-label {
      white-space: pre;
      font-weight: normal;
      background: $color-ocean;
      border-right: 1px solid $color-gray;
      display: flex;
      align-items: center;
      height: 35px;
      padding-left: 15px;
      padding-right: 15px;
    }

    &-button {
      position: relative;
      padding-right: 25px;
      cursor: pointer;
      border: 1px solid transparent;
      outline: none;
      height: 52px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: $font-size-normal;
      color: rgb(50, 50, 50);

      &:focus {
        border: 1px solid $color-gray;
        box-shadow: 0 0 2px $color-gray;
      }

      &-icon.icon.icon-down-arrow {
        color: $color-success;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 15px;
        width: 16px;
      }

      &-values {
        line-height: 35px;
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: pre;
      }

      &.has-values {
        color: $color-success;
      }
    }
  }
.user-form-status {
  display: flex;
  width: 400px;
  height: 49px;
  margin-top: 49px;
  align-items: center;
  justify-content: space-between;
  background-color: white!important;
  border: 1px solid $color-gray;
  border-radius: $border-radius;

  &-text {
    font-family: $font-family;
    font-size: $font-size-micro;
    font-weight: $font-weight-bold;
    color: $color-success;
    margin-left: 10px;
  }

  &-radio {
    display: flex;

    label {
      font: $font-size-small $font-family;
      margin-right: 10px;
    }
  }
}
.icon-login-eye {
  color: $color-success;
}

.Form-item-header {
  display: flex;
  justify-content: space-between;

  &-edit {
    width: 10px;
    height: 10px;
    margin-right: 10px;
  }
}

.store-permission {
  display: flex;
  margin: 10px;
  padding-bottom: 17px;
  label {
    color: $color-dark;
  }
}

.Search {
  position: relative;
  margin-bottom: 30px;

  &-icon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: $color-light;
  }

  &-close {
    position: absolute;
    right: 0;
    top: 0;

    .icon {
      width: 24px;
      height: 24px;
    }

    &:hover {
      background-color: rgba($color-warning, 0.1);
    }
  }

  &-txt {
    padding-left: 40px;
    padding-right: 40px;
  }

}
.default-store {
  margin: 10px;
}

.defaut-store-label {
  margin-left: 13px;
}
:deep() .select-dropdown-custom-selected {
  height: 54px !important;
  width: 100% !important;
  padding-right: 5px;
}
:deep() .select-dropdown-custom-options {
  width: 100% !important
}
:deep() .icon-vote-star-filled {
  color: $color-success
}
:deep() .icon-vote-star-empty {
  color: $color-light
}
.MiniPopup {
  width: 100%;
  max-height: 400px;
  &::before, &::after {
    display: none !important;
  }
  &-content {
    height: 200px;
    overflow-y: scroll;
  }
  &-header {
    padding: 15px 10px;
    top: 0;
  }
}
.Search {
  margin-bottom: unset !important;
}
.Form-item-label {
  margin-left: 0 !important;
}

</style>
